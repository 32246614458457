// src/ModalComponent.js
import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { SubmitButton } from '../DesignComponent/DesignComponents';
import { InlineWidget } from 'react-calendly';
import axios from 'axios';

// Construct Calendly URL with pre-filled name and email
const calendlyUrl = new URL("https://calendly.com/angadsingh63/30min-1");

const ModalComponent = ({ show, handleClose }) => {
  const [ipAddress, setIPAddress] = useState('')
  const [sheetData, setSheetData] = useState([])
  const [error, setError] = useState('')

  const getSpreadsheetData = async () => {
    try {
      let response = await axios.get('https://theleansuite.com/api/sheet-data');
      setSheetData(response.data.data)
    } catch (error) {
      console.error('Error adding row:', error);
    }
  }

  const appendSpreadsheet = async (newRow) => {
    try {
      let response = await axios.post('https://theleansuite.com/api/add-row', newRow);
      if (response.statusText === 'OK') {
        await getSpreadsheetData()
        alert('Row added successfully!');
      }
    } catch (error) {
      console.error('Error adding row:', error);
      alert('Error adding row');
    }
  };

  const handleUserIP = async () => {
    let response = await axios.get('https://api.ipify.org?format=json');
    setIPAddress(response.data.ip)
    let isExist = sheetData.findIndex(data => data.includes(response.data.ip))
    if (isExist === -1) {
      const newRow = { IP_Address: response.data.ip, Count: 1 };
      await appendSpreadsheet(newRow)
    } else {
      setError('User exist..!!')
    }
  }

  const getIpAdd = async () => {
    let response = await axios.get('https://api.ipify.org?format=json');
    setIPAddress(response.data.ip)
  }

  useEffect(async () => {
    await getIpAdd()
    await getSpreadsheetData();
  }, [])

  useEffect(() => {
  }, [sheetData])

  return (
    <Modal show={show} onHide={handleClose} centered size="xl" style={{ zIndex: 99999, backgroundColor: 'rgba(255, 255, 255, 0.5)' }}>
      <Modal.Header closeButton className='border-0 pb-0 text-white' style={{backgroundColor:'#1C1C4B'}}>
        <Modal.Title className='text-center w-100 fw-bold'>Need Some Help!</Modal.Title>
      </Modal.Header>
      <Modal.Body className='pt-0 border-0 text-white' style={{backgroundColor:'#1C1C4B'}}>
        <p className='text-center'>Send me your question or schedule a call for us to talk.</p>

        <InlineWidget
          // url="https://calendly.com/angadsingh63/30min-1"
          url={calendlyUrl.toString()}
          styles={{
            height: '600px',
            marginTop: '0px'
          }}
          pageSettings={{
            backgroundColor: 'ffffff',
            hideEventTypeDetails: false,
            hideLandingPageDetails: false,
            primaryColor: '272768',
            textColor: '292929'
          }}
        />
      </Modal.Body>
      <Modal.Footer className='border-0 justify-content-center text-white' style={{backgroundColor:'#1C1C4B'}}>
        <div className='d-grid'>
          <SubmitButton className="p-3 border-0 w-auto mb-2" type="button" onClick={handleUserIP}>
            Remind Me Later
          </SubmitButton>
          <button style={{ backgroundColor: 'rgb(28, 28, 75)', color: '#fff' }} className="p-3 border-0 w-auto mb-2" type="button" onClick={handleClose}>
            Not Required For Our Operations
          </button>
          <p className='text-danger'>{error}</p>
        </div>
        {/* <Button variant="secondary" onClick={handleClose}>
          Close
        </Button> */}
      </Modal.Footer>
    </Modal>
  );
};

export default ModalComponent;
